import logo from './logo.svg';
import './App.css';

function App() {
  return (
    [
      <div className="App" style={{height: "100%"}}>Artur Rashitov. Portfolio</div>,
      <a href="./tetris">Tetris</a>
    ]
  );
}

export default App;
